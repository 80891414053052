@use 'common'
@import 'react-toastify/dist/ReactToastify.css'

:root
	--common-edge-gap: 1rem
	--common-content-width-small: 32rem
	--common-content-width-normal: 50rem
	--common-content-width-wide: 80rem
	--background-color: #FFFFFF
	--text-color: #000000
	overflow-y: scroll

	// https://www.npmjs.com/package/shared-loading-indicator
	--ProgressLoadingIndicator-color: #{common.$primary-color}

	@media (min-width: 40rem)
		--common-edge-gap: 2rem

body
	margin: 0
	font-family: var(--font-Lora)
	background-color: common.$primary-color
	color: var(--text-color)

*, *::before, *::after
	box-sizing: border-box

button
	cursor: pointer
